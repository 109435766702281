import { TeamLink, Team, User } from './types'

export const organization = 'opus-12'

const sharedLinks: TeamLink[] = [
  { title: 'Sprint by Sprint Plan', url: 'https://docs.google.com/spreadsheets/d/1tRubiCgg6SjBglakfRkRRV45ylG_O7OJIi-j37m380o/edit?usp=drive_web&ouid=112090961643347895237'},
  { title: 'Demo Sign-up Sheet', url: 'https://docs.google.com/document/d/1Y4haJRLfVMy7NGVxYvuOgnM1PIQOO6u05iZScxX4dVE/edit'},
  { title: 'OKRs', url: 'https://app.asana.com/0/goals/1201297345957685/list'},
]

const swLinks: TeamLink[] = []
const geckoLinks: TeamLink[] = []
const controlsLinks: TeamLink[] = []

export const teams: Team[] = [
  { name: 'SW', project: 'kiwi', projectId: 17134430, labels: [], links: [...swLinks,...sharedLinks] },
  { name: 'Alumni', project: 'kiwi', projectId: 17134430, labels: [], links: [...swLinks,...sharedLinks] },
  { name: 'Controls', project: 'controls', projectId: 38183958, labels: [], links: [...controlsLinks,...sharedLinks] },
  { name: 'Gecko', project: 'gecko', projectId: 16257437, labels: [], links: [...geckoLinks,...sharedLinks] },
]

export const users: User[] = [
  /*  { name: 'User', username: 'username', githubUsername: 'username2', teams: ['Kiwi'] }, */
  { name: 'Pete', username: 'delaurentis12', teams: ['Leads', 'Flex', 'Alumni'] },
  { name: 'Deanna', username: 'deannaabrams', teams: ['Kiwi','Leads', 'Performance', 'SW'] },
  { name: 'Ted', username: 'tedfmyers', teams: ['Gecko','Leads','Performance', 'SW'] },
  { name: 'Hayden', username: 'hayden17', teams: ['Gecko','Performance', 'SW'] },
  { name: 'Nic', username: 'nic.schrading', teams: ['Gecko','Performance', 'Alumni'] },
  { name: 'Shailesh', username: 'shailesh36', teams: ['Controls'] },
  { name: 'Bjorn', username: 'bjorn.johnson', teams: ['Fab','Gecko','Leads', 'SW'] },
  { name: 'Sarthak', username: 'sarthak.jariwala', teams: ['Fab','Gecko', 'Alumni'] },
  { name: 'Charlotte', username: 'cwillens', teams: ['Fab', 'Kiwi', 'Leads', 'SW'] },
  { name: 'Tian', username: 'tianmei1', teams: ['Fab', 'Kiwi', 'Alumni'] },
  { name: 'Marion', username: 'marion.huebner', teams: ['Kiwi','Fab', 'Performance', 'Flex', 'Alumni'] },
  { name: 'Paul', username: 'paul.grock', teams: ['Kiwi', 'Fab', 'Performance','Flex', 'Alumni'] },
  { name: 'Mary', username: 'mkliang', teams: ['Leads', 'Fab', 'Performance', 'Flex', 'SW'] },
  { name: 'Adam', username: 'twelve_as', teams: ['Kiwi', 'Fab', 'Performance','Flex', 'SW'] },
]

// These keys are used for OAuth2 authentication
// You can generate a clientId in GitLab or GitHub for your app
// The redirect URI for the app must match the URL of your server
// When doing this, you can use http://localhost:3000 for local development
// and https://planner.companyname.com for production
export const vendorKeysByEnvironment: any = {
  development: {
    github: {
      clientId: 'XX',
    },
    gitlab:{
      clientId: 'f5821e3a07abbce86a2019b669b7418a06c765063e3c123b01b385f23572c121'
    }
  },
  production: {
    github: {
      clientId: 'XX',
    },
    gitlab: {
      clientId: '325bae92a5d84c19b4c3c41e2d06f4ff5ccde62fc3ededaa026385bdc52c088a'
    }
  }
};
